import { Badge, Body1, Body1Strong, Button, Caption1Stronger, Card, CardFooter, CardHeader, Dialog, DialogActions, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Dropdown, Field, Input, InputOnChangeData, Link, Option, Radio, RadioGroup, SelectTabData, SelectTabEvent, Spinner, Tab, TabList, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Text, Title1, Title2, Title3 } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { getCategories, getPosts, getWebsiteMeta } from "../api/wp-api";
import { StatusCodes } from "http-status-codes";
import { Dismiss24Regular, WindowNew24Regular } from "@fluentui/react-icons";
import { WpPost } from "../api/models/wp-post";
import { WpCategory } from "../api/models/wp-category";
import "./News.scss";
import { AxiosResponse } from "axios";
import { Website } from "../api/models/website";

interface State {
    websites: Website[];
    urlInput: string;
    activeWebsiteIndex: number;
    isLoading: boolean;
    activePost?: WpPost;
    postDialogOpened: boolean;
    errorMessage: string;
    postsPerPage: number;
    fetchUpperPagePosts: boolean;
}

const columns = [
    { columnKey: "date", label: "Date" },
    { columnKey: "title", label: "Title" },
    { columnKey: "categories", label: "Categories" },
    { columnKey: "link", label: "" },
];

const orderByOptions = [
    {key: "date_desc", label: "Date descending"},
    {key: "date_asc", label: "Date ascending"},
    {key: "title_desc", label: "Title descending"},
    {key: "title_asc", label: "Title ascending"},
]

const displayTypeOptions = [
    {key: "table", label: "Table"},
    {key: "grid", label: "Grid"},
]

const dateFilterOptions = [
    {key: "week", label: "7 days"},
    {key: "month", label: "30 days"},
    {key: "quarter", label: "3 months"},
    {key: "halfYear", label: "6 months"},
    {key: "year", label: "12 months"},
    {key: "all", label: "All time"},
]

export default function News() {
    const [state, setState] = useState<State>({
        websites: [],
        urlInput: "",
        activeWebsiteIndex: 0,
        isLoading: false,
        postDialogOpened: false,
        errorMessage: "",
        postsPerPage: 100,
        fetchUpperPagePosts: false,
    });

    useEffect(() => {
        if(state.fetchUpperPagePosts === true) {
            const url = getFullUrl();
            fetchUpperPagePosts(url);
            setActiveDateFilter();
            setState(prevState => ({
                ...prevState,
                fetchUpperPagePosts: false
            }));
        }
    }, [state.fetchUpperPagePosts])

    const fetchData = async () => {
        setState(prevState => ({
            ...prevState,
            posts: [],
            isLoading: true,
        }));
        const url = getFullUrl();
        if(isUrlValid(url)) {
            try {
                const [websiteMetaResult, postResult, categoryResult] = await Promise.all([
                    fetchWebsiteMeta(url),
                    fetchFirstPagePosts(url),
                    fetchAllCategories(url),
                ]);
                if(websiteMetaResult.status === StatusCodes.OK && postResult.status === StatusCodes.OK && categoryResult.status === StatusCodes.OK) {
                    const name = websiteMetaResult.data.name;
                    const categories = parseCategoryData(categoryResult.data);
                    const posts = parsePostData(postResult.data);
                    const totalPosts = parseInt(postResult.headers["x-wp-total"]);
                    const totalPages = Math.ceil(totalPosts / state.postsPerPage);

                    const website: Website = {
                        name: name,
                        url: url,
                        posts: posts,
                        categories: categories,
                        displayType: "table",
                        orderBy: "date_desc",
                        totalPosts: totalPosts,
                        totalPages: totalPages,
                        activeDateFilter: "all",
                    }

                    let websites = state.websites;
                    let activeWebsiteIndex = websites.findIndex(website => website.url === url);
                    if(activeWebsiteIndex == -1) {
                        activeWebsiteIndex = websites.length;
                    }
                    websites[activeWebsiteIndex] = website;

                    setState(prevState => ({
                        ...prevState,
                        websites: websites,
                        activeWebsiteIndex: activeWebsiteIndex,
                        errorMessage: "",
                        fetchUpperPagePosts: true,
                    }));
                }
            } catch(e) {
                console.log(e);
                setState(prevState => ({
                    ...prevState,
                    errorMessage: "No posts found on " + state.urlInput
                }));
            }
        } else {
            setState(prevState => ({
                ...prevState,
                errorMessage: "No posts found on " + state.urlInput
            }));
            console.log("URL not valid");
        }
        setState(prevState => ({
            ...prevState,
            isLoading: false,
        }));
    }

    const fetchWebsiteMeta = async (url: string) => {
        const websiteMetaResult = await getWebsiteMeta(url);
        return websiteMetaResult;
    }
    
    const fetchFirstPagePosts = async (url: string) => {
        const postResult = await getPosts(url);
        return postResult;
    }

    const  fetchUpperPagePosts = async (url: string) => {
        let page = 2;
        while(page <= state.websites[state.activeWebsiteIndex].totalPages) {
            const newPostResult = await getPosts(url, page);
            if(newPostResult.status === StatusCodes.OK) {
                const newPosts = parsePostData(newPostResult.data);
                
                let websites = state.websites;
                websites[state.activeWebsiteIndex].posts.push(...newPosts);

                setState(prevState => ({
                    ...prevState,
                    websites: websites
                }));
                page++;
            }
        }
    }

    const fetchAllCategories = async (url: string) => {
        let categorysResult = await getCategories(url);
        const totalCategories = parseInt(categorysResult.headers["x-wp-total"]);
        let page = 2;
        while(categorysResult.data.length < totalCategories) {
            const newCategoryResult = await getCategories(url, page);
            categorysResult.data = categorysResult.data.concat(newCategoryResult.data);
            page++;
        }
        return categorysResult;
    }

    const parsePostData = (postData: any[]) => {
        const posts = postData.map(p => {
            const post: WpPost = {
                id: p.id,
                link: p.link,
                title: htmlToPlainText(p.title.rendered),
                excerpt: htmlToPlainText(p.excerpt.rendered),
                content: parsePostContent(p.content.rendered),
                date: new Date(p.date),
                categories: p.categories,
            };
            return post;
        })
        return posts;
    }

    const parseCategoryData = (categoryData: any[]) => {
        const categories = categoryData.map(c => {
            const category: WpCategory = {
                id: c.id,
                name: c.name
            };
            return category; 
        });
        return categories;
    }

    const htmlToPlainText = (html: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const plainText = doc.body.textContent || '';
        return plainText;
    }

    const parsePostContent = (postContent: string) => {
        const ret = postContent.replace(/\[[^\]]*\]/g, '');
        return ret;
    }

    const setActiveDateFilter = () => {
        for(let i = 0; i < dateFilterOptions.length; i++) {
            if(getFilteredPosts(dateFilterOptions[i].key).length > 0) {
                setState(prevState => ({
                    ...prevState,
                    activeDateFilter: dateFilterOptions[i].key
                }));
                break;
            }
        }
    }

    const getFilteredPosts = (activeDateFilter: string = "") => {
        if(!activeDateFilter) {
            activeDateFilter = state.websites[state.activeWebsiteIndex].activeDateFilter;
        }
        let minDate = new Date();
        switch(activeDateFilter) {
            case "week": {
                minDate.setDate(new Date().getDate() - 7); break;
            } case "month": {
                minDate.setDate(new Date().getDate() - 30); break;
            } case "quarter": {
                minDate.setMonth(new Date().getMonth() - 3); break;
            } case "halfYear": {
                minDate.setMonth(new Date().getMonth() - 6); break;
            } case "year": {
                minDate.setMonth(new Date().getMonth() - 12); break;
            } case "all": {
                minDate = new Date(0);
            }
        }
        return state.websites[state.activeWebsiteIndex]?.posts.filter(post => {
            return post.date >= minDate;
        })
    }

    const getCategoryById = (categoryId: number) => {
        let ret = state.websites[state.activeWebsiteIndex].categories.find(category => category.id === categoryId);
        if(!ret) {
            ret = {id: -1, name: ""};
        }
        return ret;
    } 

    const getPostCategoriesString = (post: WpPost) => {
        let ret = "";
        post.categories.forEach(catId => {
            const category = getCategoryById(catId);
            if(category != undefined) {
                ret += category.name + ", "
            }
        });
        if(ret) {
            ret = ret.slice(0, -2);
        }
        return ret;
    }

    const getFullUrl = (url?: string) => {
        if(!url) {
            url = state.urlInput ? state.urlInput : "";
        } 
        url = url.replace("http://", "https://");
        if(!url.includes("https://")) {
             url = "https://" + url; 
        }
        return url;
    }

    const getTabList = () => {
        return (state.websites.map((website, index) => <Tab value={index}>{website.name} <Dismiss24Regular onClick={() => removeWebsite(index)} className="news-tab__close-icon" height="10px" width="10px"/></Tab>))
    }
 
    const isUrlValid = (url: string) => {
        const urlPattern = /^(http|https):\/\/[^ "]+$/;
        return urlPattern.test(url);
    } 

    const onWebsiteUrlChange = (ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setState(prevState => ({
            ...prevState,
            urlInput: data.value,
        }));
    }

    const onOrderByChange = (event: any, data: any) => {
        const val = data.optionValue;
        let posts = state.websites[state.activeWebsiteIndex].posts;
        
        switch(val) {
            case "date_asc": {
                posts.sort((p1, p2) => {
                    return p1.date.getTime() - p2.date.getTime();
                });
                break;
            } case "date_desc": {
                posts.sort((p1, p2) => {
                    return p2.date.getTime() - p1.date.getTime();
                });
                break;
            } case "title_asc": {
                posts.sort((p1, p2) => {
                    return p1.title.localeCompare(p2.title);
                });
                break;
            } case "title_desc": {
                posts.sort((p1, p2) => {
                    return p2.title.localeCompare(p1.title);
                });
                break;
            }
        }

        let websites = state.websites;
        websites[state.activeWebsiteIndex].posts = posts;
        websites[state.activeWebsiteIndex].orderBy = val;

        setState(prevState => ({
            ...prevState, 
            websites: websites,
        }));
    }

    const onDisplayTypeChange = (event: any, data: any) => {
        let websites = state.websites;
        websites[state.activeWebsiteIndex].displayType = data.optionValue;
        setState(prevState => ({
            ...prevState, 
            websites: websites,
        }));
    }

    const onFilterOptionChange = (event: any, data: any) => {
        let websites = state.websites;
        websites[state.activeWebsiteIndex].activeDateFilter = data.value;
        setState(prevState => ({
            ...prevState,
            websites: websites,
        }));
    }

    const onWebsiteSelect = (event: any, data: any) => {
        setState(prevState => ({
            ...prevState,
            activeWebsiteIndex: parseInt(data.value),
        }));
    }

    const removeWebsite = (index: number) => {
        let websites = state.websites;
        websites.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            websites: websites,
        }));
    }

    return (
      <div className="news-tab__wrapper">
        <div className="news-tab__header">
            <Field
              className="news-tab__website-input news-tab__input-field"
              label={"Website URL"}
              validationMessage={
                state.urlInput == "" ? "Website URL can't be empty" : ""
              }
            >
              <Input
                className="news-tab__input-element"
                value={state.urlInput}
                onChange={onWebsiteUrlChange}
              />
            </Field>
            <Button
              appearance="primary"
              onClick={fetchData}
              className="news-tab__fetch-button"
              disabled={state.urlInput == ""}
            >
              Fetch News
            </Button>
        </div>
        <hr style={{ width: "100%" }} />
        <TabList
          onTabSelect={onWebsiteSelect}
          selectedValue={state.activeWebsiteIndex}
          className="news-tab__website-tabs"
        >
          {getTabList()}
        </TabList>
        {state.activeWebsiteIndex != -1 && state.websites[state.activeWebsiteIndex] && <div className="news-tab__controls">
        <Field className="news-tab__date-filter">
          <RadioGroup layout="horizontal" onChange={onFilterOptionChange}>
            {dateFilterOptions.map((option) => (
              <Radio
                value={option.key}
                label={option.label}
                checked={
                  option.key ===
                  state.websites[state.activeWebsiteIndex]?.activeDateFilter
                }
                disabled={getFilteredPosts(option.key)?.length == 0}
                className={
                  getFilteredPosts(option.key)?.length == 0
                    ? "news-tab__date-filter_hidden"
                    : ""
                }
              />
            ))}
          </RadioGroup>
        </Field>
        <Field className="news-tab__input-field" label={"Order by"}>
          <Dropdown
            className="news-tab__input-element news-tab__input-element_dropdown"
            multiselect={false}
            onOptionSelect={onOrderByChange}
            selectedOptions={[state.websites[state.activeWebsiteIndex]?.orderBy]}
            value={orderByOptions.find(option => option.key === state.websites[state.activeWebsiteIndex]?.orderBy)?.label}
          >
            {orderByOptions.map((type) => (
              <Option key={type.key} value={type.key}>
                {type.label}
              </Option>
            ))}
          </Dropdown>
        </Field>
        <Field className="news-tab__input-field" label={"Display type"}>
          <Dropdown
            className="news-tab__input-element news-tab__input-element_dropdown"
            multiselect={false}
            onOptionSelect={onDisplayTypeChange}
            selectedOptions={[state.websites[state.activeWebsiteIndex]?.displayType]}
            value={displayTypeOptions.find(option => option.key === state.websites[state.activeWebsiteIndex]?.displayType)?.label}
          >
            {displayTypeOptions.map((type) => (
              <Option key={type.key} value={type.key}>
                {type.label}
              </Option>
            ))}
          </Dropdown>
        </Field>
        </div>}
        {state.isLoading && <Spinner />}
        {!state.isLoading &&
          state.websites[state.activeWebsiteIndex]?.displayType === "table" &&
          state.websites[state.activeWebsiteIndex]?.posts.length > 0 && (
            <Table className="news-tab__articles-table" as="div">
              <TableHeader>
                <TableRow className="articles-table__row">
                  {columns.map((column) => (
                    <TableHeaderCell key={column.columnKey}>
                      <Caption1Stronger>{column.label}</Caption1Stronger>
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {getFilteredPosts().map((post) => (
                  <TableRow className="articles-table__row">
                    <TableCell>{post.date.toDateString()}</TableCell>
                    <TableCell>
                      <Body1Strong>{post.title}</Body1Strong>
                    </TableCell>
                    <TableCell>{getPostCategoriesString(post)}</TableCell>
                    <TableCell>
                      <Dialog>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="transparent">
                            Open <WindowNew24Regular />
                          </Button>
                        </DialogTrigger>
                        <DialogSurface className="post-dialog">
                          <DialogTitle as="h1" className="post-dialog__title">
                            <Title1 align="center">{post.title}</Title1>
                          </DialogTitle>
                          <DialogContent className="post-dialog__content">
                            <div
                              dangerouslySetInnerHTML={{ __html: post.content }}
                            />
                          </DialogContent>
                        </DialogSurface>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        {!state.isLoading &&
          state.websites[state.activeWebsiteIndex]?.displayType === "grid" &&
          state.websites[state.activeWebsiteIndex]?.posts.length > 0 && (
            <div className="news-tab__articles-grid">
              {getFilteredPosts().map((post) => (
                <Card>
                  <CardHeader
                    header={<Title3>{post.title}</Title3>}
                    description={
                      <div className="news-tab__article-card">
                        <p>
                          <Body1>{post.excerpt}</Body1>
                        </p>
                        <div className="article-card__categories">
                          {post.categories.map((catId) => {
                            const catName = getCategoryById(catId).name;
                            return (
                              <Badge appearance="outline">{catName}</Badge>
                            );
                          })}
                        </div>
                      </div>
                    }
                  />
                  <CardFooter
                    className="article-card__footer"
                    action={
                      <Dialog>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="outline">
                            Open <WindowNew24Regular />
                          </Button>
                        </DialogTrigger>
                        <DialogSurface className="post-dialog">
                          <DialogTitle as="h1" className="post-dialog__title">
                            <Title1 align="center">{post.title}</Title1>
                          </DialogTitle>
                          <DialogContent className="post-dialog__content">
                            <div
                              dangerouslySetInnerHTML={{ __html: post.content }}
                            />
                          </DialogContent>
                        </DialogSurface>
                      </Dialog>
                    }
                  />
                </Card>
              ))}
            </div>
          )}
        {!state.isLoading &&
          state.websites[state.activeWebsiteIndex]?.posts.length == 0 &&
          state.errorMessage && (
            <Body1Strong color="red">{state.errorMessage}</Body1Strong>
          )}
      </div>
    );
}