import axios from "axios";

const restApiSuffix = "/wp-json/";

export const getWebsiteMeta = async (websiteUrl: string) => {
    const url = websiteUrl + restApiSuffix;
    return axios.get(url);
}

export const getPosts = async (websiteUrl: string, page: number = 1) => {
    const url = websiteUrl + restApiSuffix + "wp/v2/posts?per_page=100&page=" + page;
    return await axios.get(url);
}

export const getCategories = async (websiteUrl: string, page: number = 1) => {
    const url = websiteUrl + restApiSuffix + "wp/v2/categories?per_page=100&page=" + page;
    return await axios.get(url);
}